/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/style-prop-object */
/* eslint-disable complexity */
import React from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet";
import ErrorPage from "@mymoons/error-page";
import Shell from "../Shell/Shell";
import styles from "./Appointment.module.css";
import Middleware from "../../Api/Middleware";
import Branches from "../Branches/Branches";
import LoaderFullScreen from "../LoaderFullScreen/LoaderFullScreen";
import Utils from "../../Utils/utils";
import {
  CENTER_STEP,
  DATE_STEP,
  ACCEPT_STEP,
  SUCCESS_STEP,
  CHECKOUT_STEP,
  FREE_APPOINMENT_VARIANT_MEDELLIN,
  FREE_APPOINMENT_VARIANT_BUCARAMANGA,
  PROMO_APPOINMENT_VARIANT_MEDELLIN,
  PROMO_APPOINMENT_VARIANT_BUCARAMANGA,
  servicesList,
  variantsBySource,
} from "../../Utils/constants";
import ChooseDates from "../ChooseDates/ChooseDates";
import Accept from "../Accept/Accept";
import Success from "../Success/Success";
import RedirectSuccess from "../RedirectSuccess/RedirectSuccess";
import apiAcuity from "../../Api/Acuity";
import { setTrackingPatient } from "../../Utils/tracking";
import { paramsObject } from "../../Utils/createUrlParams";

import { auth, new_car } from "../../Api/Cart";

/**
 * Appointment Component
 * @return {React.Component} main Component
 */
class Appointment extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props);
    let params = {};
    if (props && props.location && props.location.search) {
      params = queryString.parse(props.location.search);
    }
    const queryParams = paramsObject();
    const { Journey_Variant, scenterPromo, services } = queryParams;
    const transactionId = params.transactionId ? params.transactionId : false;
    const appointmentTypeId = params.appointmentTypeId
      ? params.appointmentTypeId
      : false;
    const redirectUrl = params.redirectUrl ? params.redirectUrl : undefined;

    this.state = {
      steps: [
        {
          name: CENTER_STEP,
          current: true,
        },
        {
          name: DATE_STEP,
          current: false,
        },
        {
          name: ACCEPT_STEP,
          current: false,
        },
        {
          name: SUCCESS_STEP,
          current: false,
        },
        {
          name: CHECKOUT_STEP,
          current: false,
        },
      ],
      error: false,
      patient: {},
      branches: [],
      currentBranch: "",
      dateSelected: "",
      timeZone: true,
      appointment: "",
      appointmentId: "",
      requirePayment: false,
      isPaid: !!transactionId,
      // paymentId: false,
      onsite: false,
      transactionId,
      appointmentTypeId,
      reason: {},
      redirectUrl,
      journeyVariantPromo: Journey_Variant,
      scenterPromo,
      counterPromo: false,
      syncMinutes: "00",
      syncSeconds: "00",
      ecomerce_token: "",
      new_checkout_car_id: null,
      checkout_variant: "new",
      cart_created: false,
      services,
    };
  }

  getSmileCentersReatail = (Country) => {
    Middleware.getTable(
      process.env.REACT_APP_MIDDLEWARE_ITEMS,
      "SmileCenter",
      {
        type: "asc",
        column: "Order",
      },
      {
        Country,
        Active: true,
        Appointment_Type: process.env.REACT_APP_APPOINTMENT_TYPE
      },
      this.success,
      this.error
    );
  };

  appointmentIsService = () => {
    if (
      this.state.patient &&
      this.state.patient.Appointment_Status &&
      this.state.patient.Appointment_Status === "Scheduled"
    ) {
      const crmAppointmentId =
        this.state.patient && this.state.patient.Appointment_Id;
      Middleware.getTable(
        process.env.REACT_APP_MIDDLEWARE_ITEM,
        "DealAppointment",
        null,
        {
          Appointment_Id: crmAppointmentId,
        },
        this.appointmentIsServiceSuccess,
        this.appointmentIsServiceError
      );
    } else {
      const Country = this.state.patient.Country_Ops;
      this.getSmileCentersReatail(Country);
    }
  };

  appointmentIsServiceSuccess = (data) => {
    const { DealAppointment } = data;
    const { history } = this.props;
    if (
      DealAppointment.Appointment_Reason &&
      servicesList.includes(DealAppointment.Appointment_Reason)
    ) {
      if (history && history.location) {
        const search = queryString.parse(history.location.search);
        search.services = DealAppointment.Appointment_Reason;
        history.push(`?${new URLSearchParams(search).toString()}`);
        this.getSmileCentersServices(DealAppointment.Appointment_Reason);
      }
    } else {
      const Country = this.state.patient.Country_Ops;
      this.getSmileCentersReatail(Country);
    }
  };

  appointmentIsServiceError = (data) => {
    const Country = this.state.patient.Country_Ops;
    this.getSmileCentersReatail(Country);
  };

  getSmileCentersServices = (services) => {
    const Country = this.state.patient.Country_Ops;
    Middleware.getTable(
      process.env.REACT_APP_MIDDLEWARE_ITEMS,
      "SmileCenter",
      {
        type: "asc",
        column: "Order",
      },
      {
        Country,
        [`Services.${services}.productId`]: services,
      },
      this.success,
      this.error
    );
  };

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount = () => {
    const queryParams = paramsObject();
    const { Journey_Variant, services } = queryParams;
    const { match } = this.props;
    const params = match && match.params ? match.params : null;
    const PublicKey = params ? params.publicKey : "";
    const onsite = process.env.REACT_APP_APPOINTMENT_JOURNEY
      ? process.env.REACT_APP_APPOINTMENT_JOURNEY
      : false;
    this.setState({ onsite });
    Middleware.getTable(
      process.env.REACT_APP_MIDDLEWARE_ITEM,
      "Patient",
      null,
      { PublicKey },
      this.success,
      this.error
    );
    Journey_Variant === FREE_APPOINMENT_VARIANT_MEDELLIN ||
    Journey_Variant === FREE_APPOINMENT_VARIANT_BUCARAMANGA
      ? this.setState({ counterPromo: true })
      : null;
    //! this.state.patient.checkoutVariant && auth(this.success_auth_ecomerce);
  };

  /**
   * Component Did Update
   * @param {Object} nextProps .
   * @param {Object} nextState .
   * @return {void}
   */
  componentDidUpdate(nextProps, nextState) {
    const { patient, branches, services } = this.state;

    if (patient && patient !== nextState.patient) {
      if (services) {
        this.getSmileCentersServices(services);
      } else {
        this.appointmentIsService();
      }
    }
    
    if (branches && branches !== nextState.branches) {
      const { scenterPromo } = this.state;
      console.log('scenterPromo', scenterPromo)
      if (
        (scenterPromo === "buc" || scenterPromo === "med") &&
        patient.Country_Ops === "Colombia"
      ) {
        if (scenterPromo === "buc") {
          const branchBucarmanga = branches.find((branch, index) => {
            if (branch.Center_Name === "Bucaramanga") {
              return true;
            }
            return false;
          });
          this.setCurrentBranch(branchBucarmanga);
        } else if (scenterPromo === "med") {
          const branchBucarmanga = branches.find((branch, index) => {
            if (branch.Center_Name === "Medellin-Laureles") {
              return true;
            }
            return false;
          });
          this.setCurrentBranch(branchBucarmanga);
        } else {
          this.setCurrentBranch(branches[0]);
        }
      } else if (this.state.appointmentTypeId) {
        const branch = branches.find((branch, index) => {
          if (branch.Appointment_Type_Id === this.state.appointmentTypeId) {
            return true;
          }
          return false;
        });
        this.setCurrentBranch(branch);
      } else if (branches.length === 1) {
        console.log('hereeeee')
        this.setCurrentBranch(branches[0]);
      }
      console.log(branches.length)
      patient && this.analyzeStatus(patient);
    }
  }

  /**
   * Success Function
   * @param {object} data .
   * @return {void}
   */
  success = (data) => {
    const { services } = this.state;
    const patient = data.Patient;
    const branches = data.items;

    if (patient && patient.Patient_Name.indexOf(" ") === -1) {
      this.setState({
        error: {
          message: "Faltan los apellidos del paciente",
        },
      });
    }

    patient && this.setState({ patient });
    if (branches) {
      if (services) {
        branches.map((branch) => {
          branch.Appointment_Type_Id = String(
            branch.Services[services].AppointmentTypeId
          );
        });
      }
      this.filterBranches(branches);
    }
  };

  success_anonymous_car = (data) => {
    this.setState({ ...this.state, cart_created: true });
    this.setState({ ...this.state, new_checkout_car_id: data.id });
  };

  error_anonymous_car = (data) => {};

  create_anonymous_cart = async (patient, branch=false) => {
    await new_car(
      this.state.ecomerce_token,
      this.success_anonymous_car,
      this.error_anonymous_car,
      patient,
      branch
    );
  };

  redirec_new_checkout = (
    name,
    CHECKOUT_STEP,
    patient,
    publicKey,
    redirectUrl,
    branch
  ) => {
    const queryParams = paramsObject();
    const { utm_medium, utm_reason, utm_source } = queryParams;
    if (name === CHECKOUT_STEP && patient) {
      if (!this.state.cart_created) {
        this.create_anonymous_cart(patient, branch);
      }
      const utm_medium_param = `&utm_medium=${utm_medium}`;
      const utm_reason_param = `&utm_reason=${utm_reason}`;
      const utm_source_param = `&utm_source=${utm_source}`;
      if (this.state.new_checkout_car_id) {
        const countryCheckout = {
          México: ".mymoons.mx",
          Colombia: ".mymoons.co",
          Peru: ".moons.pe",
          Chile: ".mymoons.cl"
        };
        return (
          <RedirectSuccess
            url={`${
              process.env.REACT_APP_ENVIRONMENT === "PRODUCTION"
                ? `${process.env.REACT_APP_BASIC_NEW_CHECKOUT_URL}${
                    countryCheckout[patient.Country_Ops]
                  }`
                : process.env.REACT_APP_BASIC_NEW_CHECKOUT_URL_MX
            }?cart=${this.state.new_checkout_car_id}&customerId=${
              patient.CustomerId
            }&center=${branch?.Center_Name}&redirectUrl=${
              window.location.origin
            }/${publicKey}&buttonUrl=${redirectUrl}&origin=appointmentv2&name=${
              patient.Patient_Name
            }${utm_medium !== null ? utm_medium_param : ""}${
              utm_reason !== null ? utm_reason_param : ""
            }${utm_source !== null ? utm_source_param : ""}`}
          />
        );
      }
      if (this.state.cardHolder) {
        return (
          <RedirectSuccess
            url={`${process.env.REACT_APP_CARD_HOLDER_MX}/${publicKey}?customerId=${patient.CustomerId}`}
          />
        );
      }
    }

    /*
     * currentStep.name === CHECKOUT_STEP && patient && patient.Country_Ops === 'México' && (
     * <RedirectSuccess
     *  url={`${process.env.REACT_APP_CHECKOUT_URL_MX}/checkout/cita/${patient.PaymentId}?appointmentTypeId=${appointmentTypeId}&redirectUrl=${window.location.origin}/${publicKey}&buttonUrl=${redirectUrl}`}
     * />
     */
  };

  activePaymentAppoinment = () => {
    this.setState({ requirePayment: true, counterPromo: false });
  };

  desactivePaymentAppoinment = (syncMinutes, syncSeconds) => {
    this.setState({
      requirePayment: false,
      counterPromo: true,
      syncMinutes,
      syncSeconds,
    });
  };

  filterBranches = (branches) => {
    let newBranches;
    const queryParams = paramsObject();
    const { services } = queryParams;
    const { patient } = this.state;
    newBranches = branches;
    this.setState({ branches: newBranches });
  };

  /**
   * Error Function
   * @param {object} error .
   * @return {void}
   */
  error = (error) => {
    this.setState({ error });
  };

  /**
   * Analyze Status
   * @param {object} patient .
   * @return {void}
   */
  analyzeStatus = async (patient) => {
    const queryParams = paramsObject();
    const { Journey_Variant, scenterPromo } = queryParams;
    const { branches } = this.state;
    const appointmentTypeID = patient && patient.Appointment_Type_Id;
    const appointmentId = patient && patient.Appointment_Id;
    const appointmentStatus = patient && patient.Appointment_Status;
    const requirePayment = !(
      Journey_Variant === FREE_APPOINMENT_VARIANT_MEDELLIN ||
      Journey_Variant === FREE_APPOINMENT_VARIANT_BUCARAMANGA
    );
    let cardHolder = false;
    let cardCharged = patient.Affiliate ? patient.Affiliate : false;
    let isPaid = !!(patient && patient.Scan_Method_Paid);
    let isFree = true;
    let appointmentDate =
      patient && patient.Appointment_Date && patient.Appointment_Date.iso;
    appointmentDate = moment(appointmentDate);
    const now = moment();

    if (!isPaid && (cardCharged == "Charged" || cardCharged == "Holded")) {
      isPaid = true;
    } else {
      if (!isPaid && this.state.transactionId) {
        isPaid = true;
      }
      if (!isPaid && patient.Journey_Variant == "card_holder") {
        cardHolder = true;
      }
    }

    isPaid = isFree || isPaid;

    if (this.state.transactionId && patient.Journey_Variant == "card_holder") {
      cardCharged = "Holded";
    }

    let currentBranch = {}
    if(patient.Calendar_Id){
      currentBranch =
      Array.isArray(branches) &&
      branches.filter(
        (branch) => branch.Calendar_Id.toString() === patient.Calendar_Id
      )[0];
    }else{
      currentBranch = Array.isArray(branches) &&
      branches.filter(
        (branch) => branch.Appointment_Type_Id.toString() === appointmentTypeID
      )[0];
    }

    if (
      appointmentStatus === "Scheduled" ||
      appointmentStatus === "Confirmed"
    ) {
      currentBranch && this.setState({ currentBranch });
      appointmentDate && this.setState({ dateSelected: appointmentDate });
      if (appointmentDate > now) {
        if (
          requirePayment &&
          !isPaid &&
          !this.state.transactionId &&
          !this.state.appointmentTypeId
        ) {
          await this.goToSpecificsStep(CHECKOUT_STEP);
        } else if (this.state.appointmentTypeId) {
          await this.goToSpecificsStep(DATE_STEP);
          this.setState({ timeZone: true });
        } else {
          await this.setState({ timeZone: true });
          this.goToSpecificsStep(SUCCESS_STEP);
        }
      }
    }

    const stateAux = { isPaid, requirePayment };
    if (appointmentId) {
      stateAux.appointmentId = appointmentId;
    }
    this.setState({
      appointmentId,
      isPaid,
      requirePayment,
      isFree,
      cardHolder,
      cardCharged,
    });
    this.trackFreshpaintEvent();
  };

  /**
   * Go To Specifics Step
   * @param {String} stepName .
   * @return {void}
   */
  goToSpecificsStep = (stepName) => {
    const { steps } = this.state;
    const newSteps = JSON.parse(JSON.stringify(steps));

    const indexCurrentStep = steps.findIndex((step) => step.current === true);
    const nextIndex = steps.findIndex((step) => step.name === stepName);
    newSteps[indexCurrentStep].current = false;
    newSteps[nextIndex].current = true;
    this.setState({ steps: newSteps });
  };

  /**
   * Track Freshpaint
   * @return {void}
   */
  trackFreshpaintEvent = () => {
    const { steps } = this.state;
    const currentStep = steps.filter((step) => step.current === true)[0];
    const { patient } = this.state;
    let data = {
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      public_key: patient.PublicKey,
      name: patient.Patient_Name,
      email: patient.Email,
      phone: patient.Phone,
      platform: "appointment_platform_v2",
    };

    if (currentStep.name == "center") {
      setTrackingPatient(patient, {
        name: "Old Appointment Platform Centers - Page Viewed",
        data,
      });
    }
  };

  /**
   * Select Time
   * @param {String} time .
   * @return {void}
   */
  selectTime = (time) => {
    this.setState({ dateSelected: time?.time || time, slotCalendarId: time?.calendarId, slotAppointmentTypeId: time?.appointmentTypeId });
    this.nextStep();
  };

  /**
   * Set Appointment
   * @param {Object} appointment .
   * @return {void}
   */
  setAppointment = (appointment) => {
    this.setState({ appointment });
    this.nextStep();
  };

  setReason = (reason) => {
    this.setState({ reason });
  };

  /**
   * Set Current Branch
   * @param {object} currentBranch .
   * @return {void}
   */
  setCurrentBranch = (currentBranch) => {
    this.setState({ currentBranch });
    this.nextStep();
  };

  /**
   * New Appointment
   * @return {void}
   */
  cancelAppointment = async () => {
    const { appointment, appointmentId, reason } = this.state;
    let response = "";
    const queryParams = paramsObject();
    const { utm_source: utmSource, utm_term: utmTerm } = queryParams;
    if (appointment)
      response = await apiAcuity.cancel(
        appointment.id,
        reason,
        utmSource,
        utmTerm
      );
    else if (appointmentId)
      response = await apiAcuity.cancel(
        appointmentId,
        reason,
        utmSource,
        utmTerm
      );
    this.setState({ timeZone: true });
    return response;
  };

  /**
   * Next Steps
   * @param {object} isBack .
   * @return {void}
   */
  nextStep = (isBack = false, goToTheSpecificStepReschedule = false) => {
    const { steps, isPaid, journeyVariantPromo } = this.state;
    const newSteps = JSON.parse(JSON.stringify(steps));
    let count = 1;
    let lessIndexCurrentStep = 1;
    const indexCurrentStep = steps.findIndex((step) => step.current === true);
    if (
      journeyVariantPromo === FREE_APPOINMENT_VARIANT_MEDELLIN ||
      journeyVariantPromo === FREE_APPOINMENT_VARIANT_BUCARAMANGA ||
      journeyVariantPromo === PROMO_APPOINMENT_VARIANT_MEDELLIN ||
      journeyVariantPromo === PROMO_APPOINMENT_VARIANT_BUCARAMANGA ||
      goToTheSpecificStepReschedule
    ) {
      if (
        journeyVariantPromo === FREE_APPOINMENT_VARIANT_MEDELLIN ||
        journeyVariantPromo === FREE_APPOINMENT_VARIANT_BUCARAMANGA
      ) {
        if (!this.state.counterPromo) {
          lessIndexCurrentStep = 2;
          if (indexCurrentStep === 2 && !isPaid) {
            count = 2;
          }
        } else if (goToTheSpecificStepReschedule) {
          lessIndexCurrentStep = 2;
          if (indexCurrentStep === 2 && !isPaid) {
            count = 2;
          }
        }
      } else {
        lessIndexCurrentStep = 2;
        if (indexCurrentStep === 2 && !isPaid) {
          count = 2;
        }
      }
    } else {
      lessIndexCurrentStep = 2;
      if (indexCurrentStep === 2 && !isPaid) {
        count = 2;
      }
    }

    if (isBack && indexCurrentStep > 0) count = -1;
    else if (isBack && indexCurrentStep === 0) count = 0;

    if (indexCurrentStep === newSteps.length - lessIndexCurrentStep) {
      newSteps[indexCurrentStep].current = false;

      if (
        journeyVariantPromo === FREE_APPOINMENT_VARIANT_MEDELLIN ||
        journeyVariantPromo === FREE_APPOINMENT_VARIANT_BUCARAMANGA ||
        journeyVariantPromo === PROMO_APPOINMENT_VARIANT_MEDELLIN ||
        journeyVariantPromo === PROMO_APPOINMENT_VARIANT_BUCARAMANGA
      ) {
        newSteps[1].current = true;
      } else {
        newSteps[0].current = true;
      }
    } else {
      newSteps[indexCurrentStep].current = false;
      newSteps[indexCurrentStep + count].current = true;
    }
    this.setState({ steps: newSteps });
  };

  /**
   * Render
   * @returns {void}
   */
  render() {
    const {
      error,
      patient,
      branches,
      currentBranch,
      steps,
      dateSelected,
      slotCalendarId,
      slotAppointmentTypeId,
      appointment,
      timeZone,
      reason,
      services,
      isFree,
    } = this.state;
    let { redirectUrl } = this.state;

    const userData = {
      firstName: patient && patient.Patient_Name,
      lastName: "",
      email: patient && patient.Email,
      phone: patient && patient.Phone,
    };

    const userToSuccess = {
      firstName: patient && patient.Patient_Name,
      country: patient && patient.Country_Ops,
      referralCode: patient && patient.Referral_Code,
      currencyProd: patient && patient.Currency,
    };

    if (error) {
      return <ErrorPage />;
    }

    if (!patient || Utils.isEmptyObject(patient) || branches.length === 0) {
      return <LoaderFullScreen />;
    }

    const name = (patient && patient.Patient_Name) || "";
    const currentStep = steps.filter((step) => step.current === true)[0];
    const customerId = patient && patient.CustomerId;

    redirectUrl =
      redirectUrl ||
      `${
        patient.Country_Ops === "México"
          ? process.env.REACT_APP_DASHBOARD_URL_MX
          : patient.Country_Ops === "Colombia"
          ? process.env.REACT_APP_DASHBOARD_URL_CO
          : patient.Country_Ops === "Chile"
          ? process.env.REACT_APP_DASHBOARD_URL_CL
          : process.env.REACT_APP_DASHBOARD_URL_PE
      }?customerId=${customerId}`;

    let crmAppointmentId;
    if (
      patient &&
      patient.Appointment_Status &&
      patient.Appointment_Status === "Scheduled"
    ) {
      crmAppointmentId = patient && patient.Appointment_Id;
    }
    const publicKey = patient && patient.PublicKey;
    const { onsite } = this.state;
    const partner = currentBranch.Partner ? currentBranch.Partner : false;
    const appointmentTypeId = slotAppointmentTypeId || currentBranch.Appointment_Type_Id || false;
    const buttonUrl = `&buttonUrl=${redirectUrl}`;
    return (
      <Shell
        activePaymentAppoinment={this.activePaymentAppoinment}
        desactivePaymentAppoinment={this.desactivePaymentAppoinment}
        country={patient.Country_Ops}
        patient={patient}
        closeCandidateModal={currentStep.name === SUCCESS_STEP}
        isFree={isFree}
      >
        <div className={styles.Container}>
          {patient.Country_Ops === "Chile" && (
            <Helmet>
              <script
                async=""
                crossOrigin="anonymous"
                id="viddy-analytics-script-tag"
                data-viddy-analytics-type="ga"
                src="https://d2208bmkizb2br.cloudfront.net/viddy-analytics.js"
              />
            </Helmet>
          )}
          <div className={styles.Wrapper}>
            {currentStep.name === CENTER_STEP && (
              <Branches
                patient={patient}
                publicKey={publicKey}
                name={name}
                items={branches}
                setCurrentBranch={this.setCurrentBranch}
                history={this.props.history}
              />
            )}
            {currentStep.name === DATE_STEP && (
              <ChooseDates
                branch={currentBranch}
                nextStep={this.nextStep}
                name={name}
                selectTime={this.selectTime}
                patient={patient}
                history={this.props.history}
                promoAppointment={this.state.journeyVariantPromo}
                resetPaymentAppoinment={this.resetPaymentAppoinment}
                counterPromo={this.state.counterPromo}
                services={services}
              />
            )}
          </div>
          {currentStep.name === ACCEPT_STEP && (
            <Accept
              crmAppointmentId={crmAppointmentId}
              setAppointment={this.setAppointment}
              branch={currentBranch}
              nextStep={this.nextStep}
              dateSelected={dateSelected}
              slotCalendarId={this.state.slotCalendarId}
              slotAppointmentTypeId={this.state.slotAppointmentTypeId}
              userData={userData}
              customerId={customerId}
              publicKey={publicKey}
              onsite={onsite}
              partner={partner}
              country={patient && patient.Country_Ops}
              requirePayment={this.state.requirePayment && !this.state.isPaid}
              cardHolder={this.state.cardHolder}
              isFree={this.state.isFree}
              history={this.props.history}
              reason={reason}
              promoAppointment={this.state.journeyVariantPromo}
              counterPromo={this.state.counterPromo}
              syncMinutes={this.state.syncMinutes}
              syncSeconds={this.state.syncSeconds}
            />
          )}
          {currentStep.name === SUCCESS_STEP && (
            <div className={styles.Wrapper}>
              <Success
                patient={patient}
                crmAppointmentId={crmAppointmentId}
                branch={currentBranch}
                dateSelected={dateSelected}
                userData={userToSuccess}
                appointment={appointment}
                cancelAppointment={this.cancelAppointment}
                nextStep={this.nextStep}
                timeZone={timeZone}
                transactionId={this.state.transactionId}
                history={this.props.history}
                setReason={this.setReason}
                customerId={customerId}
                publicKey={publicKey}
                journeyVariant={patient.Journey_Variant}
              />
            </div>
          )}
          {this.redirec_new_checkout(
            currentStep.name,
            CHECKOUT_STEP,
            patient,
            publicKey,
            redirectUrl,
            currentBranch
          )}

          {currentStep.name === CHECKOUT_STEP &&
            patient &&
            patient.Country_Ops === "Chile" && (
              <RedirectSuccess
                url={`${process.env.REACT_APP_CHECKOUT_URL_CL}/checkout/${
                  services ||
                  (patient.Journey_Variant &&
                  variantsBySource.Chile[patient.Journey_Variant]
                    ? variantsBySource.Chile[patient.Journey_Variant]
                    : variantsBySource.Chile["default"])
                }/${
                  patient.PaymentId
                }?appointmentTypeId=${appointmentTypeId}&redirectUrl=${
                  window.location.origin
                }/${publicKey}${
                  !services ? buttonUrl : `?services=${services}`
                }`}
              />
          )}

        </div>
        {patient.Country_Ops === "México" && (
          <>
            <Helmet>
              {/* <!-- Start of global snippet: Please do not remove Place this snippet between the <head> and </head> tags on every page of your site.-->
    <!-- Global site tag (gtag.js) - Google Marketing Platform --> */}
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=DC-12152255"
              />
              <script>
                {`window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'DC-12152255'); `}
              </script>
              {/* <!-- End of global snippet: Please do not remove --> */}

              <img
                src="https://ad.doubleclick.net/ddm/activity/src=12152255;type=invmedia;cat=mymoo0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"
                width="1"
                height="1"
                alt=""
              />
            </Helmet>
          </>
        )}
      </Shell>
    );
  }
}

export default withRouter(Appointment);
