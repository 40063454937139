/* eslint-disable camelcase */
/* eslint-disable complexity */
import React from 'react'
import moment from 'moment-timezone'
import 'moment/locale/es'
import { Helmet } from 'react-helmet'
import styles from './Success.module.css'
import messages from './Success.messages'
import cosmo from '../../Assets/images/png/astro.png'
import cosmonaut from '../../Assets/images/svg/cosmonautBlue.svg'
import ok from '../../Assets/images/svg/ok.svg'
import clock from '../../Assets/images/svg/clock.svg'
import implant from '../../Assets/images/svg/implant.svg'
import calendar from '../../Assets/images/svg/calendar.svg'
import mapa from '../../Assets/images/svg/mapa.svg'
// import Referral from '../Referral/Referral'
import Footer from '../Footer/Footer'
import FullModal from '../FullModal/FullModal'
import Confirm from '../Confirm/Confirm'
import Utils from '../../Utils/utils'
import queryString from 'query-string'
import { Button } from '@mymoons/ui-library'
import Middleware from '../../Api/Middleware'
import { setTrackingPatient } from '../../Utils/tracking'

/**
 * Success Component
 * @return {void}
 */
class Success extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {

    super(props)

    let search = {}
    let userDashboardReschedule
    if(props && props.history.location && props.history.location.search){
      search = queryString.parse(props.history.location.search)
      if(search.state){
        delete search['state']
      }
      if(search.reschedule){
        userDashboardReschedule = true
      }
    }
    props.history.push("?" + new URLSearchParams(search).toString())

    this.state = {
      showModal: false,
      showMap: true,
      imageMap: '',
      backToUserDashboardUrl: search.redirectUrl ? search.redirectUrl : undefined,
      userDashboardReschedule
    }
  }

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount() {
    const { imageMap, userDashboardReschedule } = this.state
    const { branch, patient } = this.props
    let data = {
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      name: patient.Patient_Name,
      email: patient.Email,
      phone: patient.Phone,
      platform: 'appointment_platform_v2',
    };
    setTrackingPatient(patient,{name:'Old Appointment Platform TYP -  Page Viewed', data})
    if (!imageMap) {
      this.getImage(branch)
    }
    if (userDashboardReschedule === true) {
      this.showModal()
      if(this.props && this.props.history.location && this.props.history.location.search) {
        let search = queryString.parse(this.props.history.location.search)
        if(search.reschedule) {
          delete search['reschedule']
          this.props.history.push("?" + new URLSearchParams(search).toString())
        }
      }
    }
  }

  resume = () => {
    const {
      branch, dateSelected, userData, timeZone, transactionId
    } = this.props
    const {
      Center_Name, Street, Number, Neighborhood, Appointment_Type_Id
    } = branch
    const { firstName, country } = userData

    const address = `${Street} ${Number}, ${Neighborhood}`
    const date = moment(dateSelected).format('dddd DD MMMM')
    const time = timeZone ? Utils.getDateByCountryTimeZone(dateSelected, country, Appointment_Type_Id)
      : Utils.getDateByCountryTimeZone(dateSelected, country, Appointment_Type_Id)
    return (
      <div className={styles.CenterContainer}>
        <div
          className={styles.Item}
        >
          <img
            className={styles.Icon}
            alt="icon"
            src={cosmonaut}
          />
          <div className={styles.InfoDate}>
            <p className={styles.Name}>{firstName}</p>
          </div>
          {}
        </div>
        <div
          className={styles.ItemSecond}
        >
          {branch.Center_Icon ? (
            <img
              className={styles.Icon}
              alt="icon"
              src={branch.Center_Icon}
            />
          ) : (
            <img
              className={styles.Icon}
              alt="icon"
              src={implant}
            />
          )}
          <div className={styles.AddressContainer}>
            <p className={styles.Center}>{Center_Name}</p>
            <p className={styles.Address}>{address}</p>
          </div>
        </div>
        <div
          className={styles.ItemSecond}
        >
          <img
            className={styles.Icon}
            alt="icon"
            src={clock}
          />
          <div className={styles.InfoDate}>
            <p className={styles.Dates}>{date}</p>
            <p className={styles.Address}>{time}</p>
          </div>
        </div>
        {transactionId ?
        <div className={styles.ItemSecond} >
          <img
            className={styles.Icon}
            alt="icon"
            src={calendar}
          />
          <div className={styles.InfoDate}>
            <p className={styles.Dates}>Código de confirmación</p>
            <p className={styles.Address}>{transactionId}</p>
          </div>
        </div> :null}
      </div>
    )
  }

  buildCalendarLink = () => {
    const { appointment, branch } = this.props
    const {
      Zone, Street, Apt, Neighborhood, State, country, Zip
    } = branch
    const dateTime = appointment.datetime
    const timeStart = moment(dateTime).format('YYYYMMDDTHHmmss')
    const timeEnd = moment(dateTime)
      .add(appointment.duration, 'minutes')
      .format('YYYYMMDDTHHmmss')

    const text = appointment.type
    const location = `${Zone} - ${Street}, ${encodeURIComponent(branch.Number)}, 
    ${branch.Apt === '' ? '' : `${encodeURIComponent(Apt)},`} ${
      country === 'México' ? `${Neighborhood}, ${Zip},` : ''
    } ${State}`
    const details = `${location}%0A%0AVer/modificar cita:%0A${appointment.confirmationPage}`

    const link = `https://calendar.google.com/calendar/r/eventedit?dates=${timeStart}/${timeEnd}&text=${text}&location=${location}&details=${details}&sf=true`
    return link
  }

  showModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  showCancelAppointmentModal = () => {
    const { showCancelAppointmentModal } = this.state
    this.setState({ showCancelAppointmentModal: !showCancelAppointmentModal })
  }

  backToUserDashboard = () => {
    const { backToUserDashboardUrl } = this.state
    window.open(backToUserDashboardUrl, '_self')
  }

  /**
   * Map Center.
   * @param {Object} branch .
   * @param {Object} error .
   * @returns {void} .
   */
  getImage = (branch, error) => {
    let imageMap = branch && branch.Map_Image
    const url = 'https://maps.googleapis.com/maps/api/staticmap'
    const zoom = 14
    let finalURL = `${url}?center=${branch.Latitude},${branch.Longitude}&zoom=${zoom}&scale=2&size=${400}x${250}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    finalURL = `${finalURL}&markers=color:red|${branch.Latitude},${branch.Longitude}`
    imageMap = error ? branch && branch.Map_Image : finalURL
    this.setState({ imageMap })
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const {
      appointment,
      branch,
      userData,
      dateSelected,
      cancelAppointment,
      nextStep,
      timeZone,
      setReason,
      customerId,
      publicKey
    } = this.props
    const { Notes, Prev_Videocall } = branch
    const { country } = userData
    const { showModal, showCancelAppointmentModal, showMap, imageMap, backToUserDashboardUrl} = this.state
    const linkCalendar = this.buildCalendarLink()
    const content = (
      <Confirm
        type='reschedule'
        branch={branch}
        dateSelected={dateSelected}
        userData={userData}
        showModal={this.showModal}
        nextStep={nextStep}
        country={country}
        timeZone={timeZone}
        setReason={setReason}
        customerId={customerId}
        journeyVariant={this.props.journeyVariant}
      />
    )

    const contentCancelAppointment = (
      <Confirm
        type='cancel'
        branch={branch}
        dateSelected={dateSelected}
        userData={userData}
        showModal={this.showCancelAppointmentModal}
        cancelAppointment={cancelAppointment}
        nextStep={nextStep}
        country={country}
        timeZone={timeZone}
        history= {this.props.history}
        setReason={setReason}
        customerId={customerId}
        publicKey= {publicKey}
        journeyVariant={this.props.journeyVariant}
      />
    )

    const showImageMap = branch && showMap

    return (
      <div className={styles.ContainerSuccess}>
         {country === 'Chile' && 
          <Helmet>
            <script type="text/javascript">
              {`!function(e,n){var s=n.createElement("script");s.type="text/javascript",s.crossOrigin="anonymous",s.async=!0,s.src="https://d2208bmkizb2br.cloudfront.net/conversion/moons-conversion.js";var t=n.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t)}(window,document);
              `}
            </script>
          </Helmet>
        }
         {country === 'México'
             && (
                 <>
                 <img src="https://secure.adnxs.com/px?id=1600493&seg=30511524&t=2" width="1" height="1" />
                 <img src="https://ad.doubleclick.net/ddm/activity/src=12152255;type=invmedia;cat=mymoo004;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt="" />
                 <img height="1" width="1" style={{ borderStyle: 'none' }} alt="" src="https://insight.adsrvr.org/track/pxl/?adv=424f6ds&ct=0:ydpsyid&fmt=3" />
                 </>
             )
       }
        <FullModal
          open={showModal}
          content={content}
        />
        <FullModal
          open={showCancelAppointmentModal}
          content={contentCancelAppointment}
        />
        <img
          className={styles.Cosmonaut}
          src={cosmo}
          alt="cosmo"
        />
        <div className={styles.Thanks}>
          <div className={styles.ThanksContainer}>
            <img
              className={styles.Ok}
              src={ok}
              alt="ok"
            />
            <p className={styles.ThanksText}>{messages.thanks}</p>
          </div>
        </div>
        { (backToUserDashboardUrl && country === 'México' && Prev_Videocall)&& (
          <>
            <p className={styles.Title}>{messages.title}</p>
            <p className={styles.Description}>{messages.description}</p>
            <Button
              color='red'
              label={messages.appointmentVideocall}
              href={`${process.env.REACT_APP_VIDEOCALL_URL_MX}/${publicKey}?redirectUrl=${backToUserDashboardUrl}`}
              size="medium"
              variant='filled'
              className={styles.ButtonBlue}
            />
          </>
        )}
        { backToUserDashboardUrl && (
          <Button
            color={(country === 'México' && Prev_Videocall) ? 'dark' : 'red'}
            label={messages.backToUserDashboard}
            onClick={() => this.backToUserDashboard()}
            size="medium"
            variant={(country === 'México' && Prev_Videocall) ? 'textProtected' : 'filled'}
            className={(country === 'México' && Prev_Videocall) ? styles.textProtected : styles.ButtonBlue}
          />
        )}
        <div className={[styles.Section, Notes ? '' : styles.Center].join(' ')}>
          <div className={styles.ResumeLink}>
            {this.resume()}
            <div className={styles.ContainerLinkCalendar}>
              <img
                className={styles.ImageCalendar}
                alt="calendar"
                src={calendar}
              />
              <a
                href={linkCalendar}
                className={styles.LinkCalendar}
                rel="noopener noreferrer"
                target="_blank"
              >
                {messages.addCalendar}
              </a>
            </div>
          </div>
          { Notes && (
            <div className={styles.Recommendation}>
              <div className={styles.ContainerRec}>
                <p className={styles.TitleRecommendation}>{messages.recommendation}</p>
                <p className={styles.ItemRec}>
                  {Notes}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className={styles.MapImagesContainer}>
          {showImageMap && (
            <div
              className={styles.MapContainer}
              role="button"
              tabIndex={0}
              onClick={() => branch.Map_URL && window.open(branch.Map_URL)}
            >
              <img
                alt="map"
                src={imageMap}
                className={styles.MapImage}
                onError={() => this.getImage(branch, true)}
              />
            </div>
          )}
          {branch && branch.Map_URL && (
            <div
              role="button"
              tabIndex={0}
              className={styles.SeeMaps}
              onClick={() => window.open(branch.Map_URL)}
            >
              <img
                alt="map"
                src={mapa}
                className={styles.IconMap}
              />
              <p>{messages.seeMaps}</p>
            </div>
          )}
        </div>
        <div
          role="button"
          tabIndex={0}
          className={styles.NewAppointment}
          onClick={() => this.showModal()}
        >
          {messages.newAppointment}
        </div>
        <div
          role="button"
          tabIndex={-1}
          className={styles.TextFooter}
          onClick={() => this.showCancelAppointmentModal()}
        >
          {messages.cancelAppointment}
        </div>
        <Footer country={country} />
      </div>
    )
  }
}

export default Success
