import { callApi, createAuthHeaderEcomerce } from "./Request";
import random from "alphanumeric";
import { getProductByKey } from "./Product";

const auth_url = process.env.REACT_APP_BASIC_AUTH_ECOMERCE_TOOLS;
const ecommerceCart = `${process.env.REACT_APP_BASIC_ECOMERCE_URL}/carts`;
const random_code = random(20);
const countryData = {
  México: {
    code:"MX",
    currency:"MXN",
    finalPrice: process.env.REACT_APP_NEW_CHECKOUT_FINAL_PRICE_MX,
    locale: "es-MX"
  },
  Colombia: {
    code:"CO",
    currency:"COP",
    finalPrice: process.env.REACT_APP_NEW_CHECKOUT_FINAL_PRICE_CO,
    locale: "es-CO"
  },
  Peru: {
    code:"PE",
    currency:"PEN",
    finalPrice: process.env.REACT_APP_NEW_CHECKOUT_FINAL_PRICE_PE,
    locale: "es-PE"
  },
  default: {
    code:"MX",
    currency:"MXN",
    finalPrice: process.env.REACT_APP_NEW_CHECKOUT_FINAL_PRICE_MX,
    locale: "es-MX"
  }
};

const findVariantByKey = (products, key) => products.find((product) => key.includes(product.key)) ?? {};
const findPriceByCountry = (prices, country) => prices.find((price) => country.includes(price.country)) ?? {};

export const validateCustomerMigration = async (customerId) => {
  const {
    REACT_APP_VALIDATE_CUSTOMER,
    REACT_APP_VALIDATE_CUSTOMER_USER: customerUser,
    REACT_APP_VALIDATE_CUSTOMER_PASS: customerPass,
  } = process.env;

  const user = customerUser;
  const password = customerPass;
  const auth = `${user}:${password}`;
  const authEncoded = `Basic ${Buffer.from(auth).toString("base64")}`;

  try {
    const apiResponse = await fetch(
      `${REACT_APP_VALIDATE_CUSTOMER}/${customerId}`,
      {
        method: "POST",
        headers: {
          Authorization: authEncoded,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          getCart: true,
        }),
      }
    );

    const data = await apiResponse.json();
    return data;
  } catch {
    return false;
  }
};

export const auth = (success, error) =>
  callApi(
    auth_url,
    {
      headers: createAuthHeaderEcomerce(),
      method: "POST",
    },
    success,
    error
  );

export const new_car = async (token, success, error, patient, branch) => {
  const product = await getProductByKey('ALDRS')
  const liteVariant = "Lite";
  const variants = [...product?.masterData?.current?.variants, product?.masterData?.current?.masterVariant]
  const variantInfo = findVariantByKey(variants, liteVariant);
  const currentCountry = countryData[patient.Country_Ops] ?? countryData.default
  const { locale } = currentCountry;
  const prices = variantInfo?.prices;
  const priceInfo = findPriceByCountry(prices, locale);
  const finalPrice = priceInfo?.value?.centAmount;
 
  const responseValidate = await validateCustomerMigration(patient.CustomerId);

  const  sku =  branch?.Center_Type === 'Liverpool' ? 'liverpool_cita_de_escaneo' :'appointment'

  const body = {
    currency: countryData[patient.Country_Ops].currency,
    key: `cart-key-${random_code}`,
    customerEmail: patient.Email,
    customerId: responseValidate.ctCustomerId,
    shippingAddress: {
      country: countryData[patient.Country_Ops].code,
    },
    country: countryData[patient.Country_Ops].code,
    lineItems: [
      {
        quantity: 1,
        sku: sku,
      },
    ],
    origin: "Customer",
    inventoryMode: "None",
  };

  if (responseValidate.activeCart) {
    try {
      const responseApiDelete = await fetch(
        `${ecommerceCart}/${responseValidate.activeCart.id}`,
        {
          method: "DELETE",
          headers: {
            "x-api-key": process.env.REACT_APP_BASIC_API_KEY_ECOMERCE,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await responseApiDelete.json();

      if (data) {
        callApi(
          ecommerceCart,
          {
            headers: {
              "x-api-key": process.env.REACT_APP_BASIC_API_KEY_ECOMERCE,
            },
            method: "POST",
            body: JSON.stringify(body),
          },
          success,
          error
        );
      }
    } catch (error) {
      console.log(error, "No se pudo borrar el carrito existente");
    }
  } else {
    callApi(
      ecommerceCart,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_BASIC_API_KEY_ECOMERCE,
        },
        method: "POST",
        body: JSON.stringify(body),
      },
      success,
      error
    );
  }
};
